<template>
    <div>
        <v-progress-linear :active="isLoading" indeterminate color="primary"></v-progress-linear>
        <GmapMap :center="centerLocation" :zoom="15" map-type-id="terrain" style="width: 100%; height: 500px">
            <gmap-info-window
                :options="infoOptions"
                :position="infoWinPosition"
                :opened="infoWinOpen"
                @closeclick="infoWinOpen = false">
                <h1 class="font-weight-black text-uppercase subtitle-1">{{ selectedFacility.name }}</h1>
                <h1 class="subtitle-2">{{ selectedFacility.address }}</h1>
                <div class="mt-5">
                    <v-btn
                        @click="$router.push({ name: 'FacilityProfile', params: { id: selectedFacility.id } })"
                        outlined
                        color="primary"
                        class="is-lowecase">
                        <v-icon left> {{ icons.mdiPlus }} </v-icon>
                        Ver mais
                    </v-btn>
                </div>
            </gmap-info-window>
            <GmapMarker
                :key="index"
                v-for="(facility, index) in facilityList"
                :position="{ lat: facility.latitude, lng: facility.longitude }"
                :icon="getMarkers(facility)"
                :clickable="true"
                :draggable="false"
                :zIndex="getZindex(facility)"
                @click="onClick(facility)" />
        </GmapMap>
    </div>
</template>

<script>
    import { mdiPlus } from '@mdi/js';
    // sidebar
    import useFacilityList from './useFacilityList';

    export default {
        props: {
            facilityList: {
                type: Array,
                required: true,
            },
        },
        setup() {
            const {
                facilityListTable,

                fetchFacilityList,
            } = useFacilityList();

            return {
                facilityListTable,

                fetchFacilityList,
                icons: {
                    mdiPlus,
                },
            };
        },
        created() {
            this.isLoading = true;

            this.fetchFacilityList();
            setTimeout(() => {
                this.isLoading = false;
            }, 1900);
        },

        data() {
            return {
                selectedFacility: {
                    name: '',
                    id: null,
                    address: '',
                },
                isLoading: false,
                trainposition: { lat: 41.40506, lng: -8.529703 },
                //yellowBus: { lat: 41.4074 , lng: -8.5111 },
                greenBus: { lat: 41.4074, lng: -8.5111 },
                redBus: { lat: 41.40506, lng: -8.529703 },
                orangeBus: { lat: 41.40506, lng: -8.529703 },
                trainicon: { url: '@/assets/icons/map/trainstop.svg' },

                options: {
                    zoomControl: true,
                    mapTypeControl: true,
                    scaleControl: false,
                    streetViewControl: true,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUI: false,
                    mapMarker: '',
                    gestureHandling: 'greedy',
                },
                myLocation: {
                    lat: 0,
                    lng: 0,
                },
                marker: {
                    url: require('@/assets/icons/map/sanitaryFacility.png'),
                    scaledSize: {
                        width: 45,
                        height: 55,
                    },
                },
                infoWinOpen: false,
                isClicked: false,
                selectedMarker: null,
                infoOptions: {
                    //optional: offset infowindow so it visually sits nicely on top of our marker
                    pixelOffset: {
                        width: 0,
                        height: -80,
                    },
                },
                infoWinPosition: {
                    lat: 0,
                    lng: 0,
                },
            };
        },
        computed: {
            centerLocation() {
                return {
                    //Estádio
                    lat: 41.4014,
                    lng: -8.5224,
                };
            },
            facilityMarkers() {
                return this.facilityListTable;
            },
        },
        methods: {
            getMarkers(facility) {
                return this.marker;
            },
            getZindex(facility) {
                if (this.selectedMarker == facility.id) {
                    return 1;
                } else {
                    return null;
                }
            },
            onClick(facility) {
                this.selectedMarker = facility.id;
                this.selectedFacility = {
                    name: facility.name,
                    id: facility.id,
                    address: facility.address,
                };

                this.infoWinOpen = true;
                this.infoWinPosition = {
                    lat: facility.latitude,
                    lng: facility.longitude,
                };
            },
        },
    };
</script>
