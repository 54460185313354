import store from '@/store';
import {
    mdiAccountCheckOutline,
    mdiAccountOutline,
    mdiAccountPlusOutline,
    mdiAccountRemoveOutline,
    mdiCogOutline,
    mdiDatabaseOutline,
    mdiDnsOutline,
    mdiPencilOutline,
} from '@mdi/js';
import { ref, watch } from '@vue/composition-api';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';
import en from '@/plugins/i18n/locales/en';

export default function useFacilitiesList() {
    const facilityListTable = ref([]);

    const tableColumns = [
        { text: i18n.t('Name'), value: 'name', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('District'), value: 'location.parent.parent.name', class: 'text-uppercase' },
        { text: i18n.t('County'), value: 'location.parent.name', class: 'text-uppercase' },
        { text: i18n.t('Parish'), value: 'location.name', class: 'text-uppercase' },
        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const searchQuery = ref('');
    const roleFilter = ref(null);
    const countryFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const totalFacilityListTable = ref(0);
    const loading = ref(false);
    const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
    });
    const facilityTotalLocal = ref([]);
    const selectedRows = ref([]);
    const oldSearch = ref('');
    const oldDistrictFilter = ref(' ');
    const oldCountryFilter = ref(' ');
    const oldCountyFilter = ref(' ');
    const oldParishFilter = ref(' ');

    // fetch data
    const fetchFacilityList = () => {
        let verifyQuery = store.getters['app-facility/getFacilityQuery'];
        let isDeleteFacility = store.getters['app-facility/getIsDeleteFacility'];
        store
            .dispatch('app-facility/fetchFacilityList', {})
            .then((response) => {
                if (isDeleteFacility) {
                    facilityListTable.value = [];
                    facilityListTable.value.push(...response.data.data);
                    store.commit('app-facility/setIsDeleteFacility', false);
                } else if (
                    oldSearch.value == verifyQuery.filters.$or[0].name.$containsi &&
                    oldDistrictFilter.value === verifyQuery.filters.location.parent.parent.id.$containsi &&
                    oldCountryFilter.value === verifyQuery.filters.location.parent.parent.parent.id.$containsi &&
                    oldCountyFilter.value === verifyQuery.filters.location.parent.id.$containsi &&
                    oldParishFilter.value === verifyQuery.filters.location.id.$containsi
                ) {
                    facilityListTable.value = [];
                    facilityListTable.value.push(...response.data.data);
                } else {
                    oldSearch.value = verifyQuery.filters.$or[0].name.$containsi;
                    oldDistrictFilter.value = verifyQuery.filters.location.parent.parent.id.$containsi;
                    oldCountryFilter.value = verifyQuery.filters.location.parent.parent.parent.id.$containsi;
                    oldCountyFilter.value = verifyQuery.filters.location.parent.id.$containsi;
                    oldParishFilter.value = verifyQuery.filters.location.id.$containsi;
                    facilityListTable.value = [];
                    facilityListTable.value.push(...response.data.data);
                }
                totalFacilityListTable.value = response.data.meta.pagination.total;

                // facilityTotalLocal.value = facilityTotal;
                // remove loading state
                loading.value = false;
            })
            .catch((error) => {
                throw error;
            });
    };

    watch([searchQuery, countryFilter, roleFilter, planFilter, statusFilter, options], () => {
        loading.value = true;
        selectedRows.value = [];
        fetchFacilityList();
    });

    const resolveFacilityPicture = (pictures) => {
        if (_.get(pictures, '[0].formats.thumbnail.url')) return _.get(pictures, '[0].formats.thumbnail.url');

        if (_.get(pictures, 'url')) return _.get(pictures, 'url');
        return null;
    };
    const resolveFacilityPictureMax = (pictures) => {
        if (_.get(pictures, '[0].formats.large.url')) return _.get(pictures, '[0].formats.large.url');

        if (_.get(pictures, '[0].url')) return _.get(pictures, '[0].url');
        return null;
    };
    const resolveFacilityRoleVariant = (role) => {
        if (role === 'subscriber') return 'primary';
        if (role === 'author') return 'warning';
        if (role === 'maintainer') return 'success';
        if (role === 'editor') return 'info';
        if (role === 'admin') return 'error';

        return 'primary';
    };

    const resolveFacilityRoleIcon = (role) => {
        if (role === 'subscriber') return mdiAccountOutline;
        if (role === 'author') return mdiCogOutline;
        if (role === 'maintainer') return mdiDatabaseOutline;
        if (role === 'editor') return mdiPencilOutline;
        if (role === 'admin') return mdiDnsOutline;

        return mdiAccountOutline;
    };

    const resolveFacilitiestatusVariant = (status) => {
        if (status === 'pending') return 'warning';
        if (status === 'active') return 'success';
        if (status === 'inactive') return 'secondary';

        return 'primary';
    };

    const resolveFacilityTotalIcon = (total) => {
        if (total === 'Total Facilities') return { icon: mdiAccountOutline, color: 'primary' };
        if (total === 'Paid Facilities') return { icon: mdiAccountPlusOutline, color: 'error' };
        if (total === 'Active Facilities') return { icon: mdiAccountCheckOutline, color: 'success' };
        if (total === 'Pending Facilities') return { icon: mdiAccountRemoveOutline, color: 'warning' };

        return { icon: mdiAccountOutline, color: 'primary' };
    };

    const limitFacilityText = (facilities) => {
        if (facilities.length > 1) {
            return ' + ' + (facilities.length - 1);
        }
    };

    return {
        facilityListTable,
        tableColumns,
        searchQuery,
        countryFilter,
        roleFilter,
        planFilter,
        statusFilter,
        totalFacilityListTable,
        loading,
        options,
        facilityTotalLocal,
        selectedRows,
        fetchFacilityList,
        resolveFacilityPicture,
        resolveFacilityPictureMax,
        resolveFacilityRoleVariant,
        resolveFacilityRoleIcon,
        resolveFacilitiestatusVariant,
        limitFacilityText,
    };
}
